/* Montserrat */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
/* Poiret One */
/* Forum */
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Forum&family=Italiana&family=Niconne&family=Poiret+One&display=swap');

*,*::before,*::after{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}

html {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f3eded; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  min-height: 100vh;
}

